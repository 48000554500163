import React, { useState, useCallback } from 'react';
import { Wrapper, Container, ButtonWrapper } from './styles';
import StyledInput from '../../Estimate/StyledInput';
import StyledCheckBox from '../../Estimate/StyledCheckBox';
import AgreeBox from '../../Estimate/AgreeBox';
import StyledButton from '../../Estimate/StyledButton';
import colors from '../../../../styles/colors';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_GENERAL_INQUIRY } from '../../../../graphql/Support/mutation';

// const medicalImagingEquipmentList = [
//   "R-DR (Digital Radiography)",
//   "CR (Computed Radiography)",
//   "X-Ray System",
//   "C-Arm",
//   "Mammography (DR/CR)",
//   "초음파",
//   "내시경",
//   "CT",
//   "MRI",
// ];
// const generalInspectionEquipmentList = [
//   "동맥경화 검사기",
//   "폐기능 검사기(Spirometer)",
//   "ECG (Cardionet-S)",
// ];
// const medicalImageProcessingUnitList = ["PACS System", "원격영상판독"];

const Inquire = () => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState([]);
  const [inputs, setInputs] = useState({
    title: '',
    name: '',
    hospitalName: '',
    region: '',
    tel: '',
    email: '',
    message: '',
  });

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  // 동의하기
  const handleAgreeCheck = useCallback(
    (_, value, isChecked) => {
      if (isChecked) {
        setAgree((prev) => [...prev, value]);
        return;
      }

      if (!isChecked && agree.includes(value)) {
        setAgree((prev) => prev.filter((item) => item !== value));
      }
    },
    [agree]
  );
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const [createGeneralInquiry] = useMutation(CREATE_GENERAL_INQUIRY);

  const handleContact = useCallback(async () => {
    const { title, name, hospitalName, region, tel, email, message } = inputs;
    if (title === '' || name === '' || hospitalName === '' || region === '' || tel === '' || email === '') {
      alert('필수 항목을 모두 입력해주세요.');
      return;
    }

    try {
      const { data } = await createGeneralInquiry({
        variables: {
          title: title,
          name: name,
          hospitalName: hospitalName,
          workArea: region,
          cellphone: tel,
          email: email,
          text: message,
        },
      });

      if (data.createGeneralInquiry) {
        handleCancel();
      }
    } catch (e) {}
  }, [inputs]);

  return (
    <Wrapper>
      <Container>
        <StyledInput REQUIRED label="제목" name="title" value={inputs.title} onChange={onChangeInputs} placeholder="제목을 입력해주세요" />
        <StyledInput REQUIRED label="이름" name="name" value={inputs.name} onChange={onChangeInputs} placeholder="이름을 입력해주세요" />
        <StyledInput
          REQUIRED
          label="병의원명"
          name="hospitalName"
          value={inputs.hospitalName}
          onChange={onChangeInputs}
          placeholder="운영 또는 개원 예정이신 병/의원명을 기입해 주세요"
        />
        <StyledInput REQUIRED label="근무지역" name="region" value={inputs.region} onChange={onChangeInputs} placeholder="근무 지역 또는 개원 예정 지역을 입력해주세요" />
        <StyledInput REQUIRED label="연락처" name="tel" type="number" value={inputs.tel} onChange={onChangeInputs} placeholder="전화번호를 입력해주세요" />
        <StyledInput REQUIRED label="E-mail" name="email" value={inputs.email} onChange={onChangeInputs} placeholder="이메일을 입력해주세요" />
        <StyledInput label="문의사항" name="message" value={inputs.message} onChange={onChangeInputs} placeholder="기타 요청사항 또는 문의사항을 입력해주세요" />
        <AgreeBox label={`개인정보 수집 및 이용안내`} item="개인정보 수집 및 이용에 대해서 동의합니다.*" values={agree} onChange={handleAgreeCheck} />
        <AgreeBox label={`유의사항`} item="유의사항 내용을 확인하였습니다.*" values={agree} onChange={handleAgreeCheck} />
      </Container>
      <ButtonWrapper>
        <StyledButton title="문의하기" fontColor={colors.blueColor} border={`1px solid ${colors.blueColor}`} onClick={handleContact} />
        <StyledButton title="취소" onClick={() => null} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Inquire;
