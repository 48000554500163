import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import logo1 from "../../../../assets/Home/banner1.png";
import logo2 from "../../../../assets/Home/banner2.png";
import banner3 from "../../../../assets/Home/banner3.png";
import colors from "../../../../styles/colors";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import {
  ArrowBox,
  BannerImage,
  Contents,
  Image,
  Line,
  SlideArrow,
  Subtitle,
  FlowTitle,
  Wrapper,
  FlowBox,
} from "./styles";
import { useQuery } from "@apollo/client";
import { SEE_HPMAIN_IMG } from "../../../../graphql/Home/query";

const SlideBanner = () => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { loading, error, data } = useQuery(SEE_HPMAIN_IMG);

  if (loading) return null;
  if (error) return console.log(error.message);
  if (!data) return null;

  let newHPMain = [];
  data.seeHPMain.map((item) => {
    if (item.hm_url !== "") {
      newHPMain.push(item.hm_url);
    }
  });

  const banners = [
    {
      id: 1,
      src: newHPMain[0],
      title: "독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트",
      subtitle:
        "최신 IT기술을 접목하여 사용자들의 편의성과 효율을 극대화한 솔루션으로 진료관리, 병원경영,\n고객관리 등 의료인이 의료행위에 집중할 수 있도록 설계된 EMR 차트입니다.",
    },
    {
      id: 2,
      src: newHPMain[1],
      title: "독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트",
      subtitle:
        "최신 It기술을 접목하여 사용자들의 편의성과 효율을 극대화한 솔루션으로 진료관리, 병원경영,\n고객관리 등 의료인이 의료행위에 집중할 수 있도록 설계된 EMR 차트입니다.",
    },
    {
      id: 3,
      src: newHPMain[2],
      title: "독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트",
      subtitle:
        "최신 It기술을 접목하여 사용자들의 편의성과 효율을 극대화한 솔루션으로 진료관리, 병원경영,\n고객관리 등 의료인이 의료행위에 집중할 수 있도록 설계된 EMR 차트입니다.",
    },
  ];

  return (
    <Wrapper>
      <FlowBox>
        <FlowTitle>
          독보적인 경험을 바탕으로 한<br />
          효율적이고 스마트한 EMR 차트
        </FlowTitle>
      </FlowBox>
      <Swiper
        autoplay={{ delay: 3500, disableOnInteraction: false }}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{ type: "fraction" }}
        modules={[Autoplay, Navigation, Pagination]}
        onSlideChange={(e) => setMainImageIndex(e.activeIndex)}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.activeIndex = mainImageIndex;
          swiper.navigation.update();
        }}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={`img-${index}`}>
            <BannerImage>
              <Image src={banner.src} />
              <Contents></Contents>
            </BannerImage>
          </SwiperSlide>
        ))}

        <ArrowBox>
          <SlideArrow ref={navigationPrevRef}>
            <IoChevronBackOutline fontSize={20} color={colors.whiteColor} />
          </SlideArrow>
          <SlideArrow ref={navigationNextRef}>
            <IoChevronForwardOutline fontSize={20} color={colors.whiteColor} />
          </SlideArrow>
        </ArrowBox>
      </Swiper>
    </Wrapper>
  );
};

export default React.memo(SlideBanner);
