import { styled } from "styled-components";
import colors from "../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3cqb;
  margin-bottom: 60px;
`;

export const Image = styled.img`
  width: 100%;
  margin-top: -30px;
  margin-bottom: 30px;
  height: 174px;
  object-fit: cover;
`;

export const DownBox = styled.div`
  display: flex;
  padding-right: 18px;
  justify-content: flex-end;
  font-weight: bold;
  align-items: center;
  margin-bottom: 30px;
`;
export const DownLoadButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  line-height: 1.1;
`;

export const ContentsWrapp = styled.div`
  display: flex;
  flex-direction: column;
  /* gap:30px; */
`;

export const Contents = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 18px;
  /* margin-bottom: 30px; */
  padding-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: ${({ $margin }) => ($margin === "bottom" ? "20px" : "0px")};
  margin-top: ${({ $margin }) => ($margin === "top" ? "20px" : "0px")};
  background-color: ${({ $border }) =>
    $border === "black" ? colors.blackColor : colors.borderGrayColor};
`;

export const TextBlock = styled.div`
  display: flex;
`;
export const Square = styled.div`
  width: 20px;
`;
export const Text = styled.div``;

export const SlideWrapper = styled.div`
  display: flex;
  height: 240px;
  position: relative;
  width: 100%;

  .swiper-pagination-horizontal {
    font-size: 12px;
    color: ${colors.blackColor};
    position: absolute;
    bottom: 0;
    right: 0px;
    right: calc((100vw - 50vw));
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;

export const SwipeImage = styled.img`
  width: 80%;
  object-fit: contain;
  height: 190px;
  padding-bottom: 10px;
`;

export const ArrowBox = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  padding: 0px 18px;
  bottom: 0;
`;
export const SlideArrow = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px dashed ${colors.blackColor}; */
  cursor: pointer;
  & + & {
    margin-left: 8px;
  }
`;
