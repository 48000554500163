import React, { useState } from "react";
import { Container, Hamburger } from "./styles";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { IoMenu, IoClose } from "react-icons/io5";
import MenuListModal from "../MenuListModal";

const GNB = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => {
    setIsMenuOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = "scroll";
  };

  return (
    <Container>
      <Link to="/">
        <Logo height="18px" width="140px" />
      </Link>
      <Hamburger onClick={openMenu}>
        {isMenuOpen ? <IoClose size={20} /> : <IoMenu size={20} />}
      </Hamburger>
      <MenuListModal closeMenu={closeMenu} isSideBarOpen={isMenuOpen} />
    </Container>
  );
};

export default GNB;
