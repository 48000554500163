import { styled } from "styled-components";
import colors from "../../styles/colors";

export const Wrapper = styled.div`
  margin-top: 30px;
`;
export const IntroContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 18px;
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const DownBox = styled.div`
  display: flex;
  padding-right: 18px;
  justify-content: flex-end;
  font-weight: bold;
  align-items: center;
  margin-bottom: 30px;
`;
export const DownLoadButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  line-height: 1.1;
`;

export const SubmitContainer = styled.div`
  display: flex;
  padding: 0px 18px;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.blackColor};
  margin: 20px 0px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 18px;
  margin-top: 30px;
  justify-content: space-around;
`;
