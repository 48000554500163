import React from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, IconBox, Icon, Contents, Title, Text } from "./styles";

const ServiceList = ({ path = "", src, title = "", contents = "" }) => {
  const navigate = useNavigate();
  return (
    <Wrapper
      onClick={() =>
        navigate(path, {
          state: {
            title,
          },
        })
      }
    >
      <IconBox>
        <Icon src={src} />
      </IconBox>
      <Contents>
        <Title>{title}</Title>
        <Text>
          {contents.split("\n").map((text, index) => (
            <span key={`text-${index}`}>
              {text}
              <br />
            </span>
          ))}
        </Text>
      </Contents>
    </Wrapper>
  );
};

export default ServiceList;
