import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Contents = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 0px;
`;
export const Title = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-weight: bold;
`;
export const Section = styled.section`
  width: 100%;
  border-top: 1px solid ${colors.borderGrayColor};
`;
export const FlexRow = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 0px;
`;
export const BoldText = styled.div`
  display: flex;
  width: 25%;
`;
export const InfoText = styled.div`
  display: flex;
  width: 75%;
`;
export const MapBox = styled.div`
  width: 100%;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
