import React, { useState, useCallback } from "react";
import {
  Title,
  Wrapper,
  IntroContainer,
  DownBox,
  DownLoadButton,
  SubmitContainer,
  Line,
  ButtonWrapper,
} from "./styles";
import MainText from "../../components/public/MainText";
import ServiceImage from "../../components/features/Home/ServiceImage";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import StyledInput from "../../components/features/Estimate/StyledInput";
import StyledCheckBox from "../../components/features/Estimate/StyledCheckBox";
import AgreeBox from "../../components/features/Estimate/AgreeBox";
import StyledButton from "../../components/features/Estimate/StyledButton";
import colors from "../../styles/colors";
import { useMutation } from "@apollo/client";
import { CREATE_ESTIMATE_INQUIRY } from "../../graphql/Estimate/mutation";

const medicalImagingEquipmentList = [
  "R-DR (Digital Radiography)",
  "CR (Computed Radiography)",
  "X-Ray System",
  "C-Arm",
  "Mammography (DR/CR)",
  "초음파",
  "내시경",
  "CT",
  "MRI",
];
const generalInspectionEquipmentList = [
  "동맥경화 검사기",
  "폐기능 검사기(Spirometer)",
  "ECG (Cardionet-S)",
];
const medicalImageProcessingUnitList = ["PACS System", "원격영상판독"];

const Estimate = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    medicalDepartment: "",
    usePCCount: "",
    medicalImagingEquipment: [],
    generalInspectionEquipment: [],
    medicalImageProcessingUnit: [],
    name: "",
    hospitalName: "",
    region: "",
    tel: "",
    email: "",
    message: "",
  });
  const [agree, setAgree] = useState([]);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  // 체크박스 선택
  const handleChecklist = useCallback(
    (name, value, isChecked) => {
      if (isChecked) {
        setInputs((prev) => ({
          ...prev,
          [name]: [...prev[name], value],
        }));
        return;
      }

      if (!isChecked && inputs[name].includes(value)) {
        setInputs((prev) => ({
          ...prev,
          [name]: prev[name].filter((item) => item !== value),
        }));
      }
    },
    [inputs]
  );

  // 동의하기
  const handleAgreeCheck = useCallback(
    (_, value, isChecked) => {
      if (isChecked) {
        setAgree((prev) => [...prev, value]);
        return;
      }

      if (!isChecked && agree.includes(value)) {
        setAgree((prev) => prev.filter((item) => item !== value));
      }
    },
    [agree]
  );

  const [createEstimate] = useMutation(CREATE_ESTIMATE_INQUIRY);

  const handleContact = useCallback(async () => {
    const {
      medicalDepartment,
      usePCCount,
      name,
      hospitalName,
      region,
      tel,
      email,
      message,
    } = inputs;

    if (
      medicalDepartment === "" ||
      usePCCount === "" ||
      name === "" ||
      hospitalName === "" ||
      region === "" ||
      tel === "" ||
      email === ""
    ) {
      alert("필수 항목을 모두 입력해주세요.");
      return;
    }

    try {
      const { data } = await createEstimate({
        variables: {
          eiMedicalSub: medicalDepartment,
          eiPcCount: usePCCount,
          eiRdr: inputs.medicalImagingEquipment.includes(
            "R-DR (Digital Radiography)"
          ),
          eiCr: inputs.medicalImagingEquipment.includes(
            "CR (Computed Radiography)"
          ),
          eiXray: inputs.medicalImagingEquipment.includes("X-Ray System"),
          eiCArm: inputs.medicalImagingEquipment.includes("C-Arm"),
          eiMammography: inputs.medicalImagingEquipment.includes(
            "Mammography (DR/CR)"
          ),
          eiUltrasonicWave: inputs.medicalImagingEquipment.includes("초음파"),
          eiEndoscope: inputs.medicalImagingEquipment.includes("내시경"),
          eiCt: inputs.medicalImagingEquipment.includes("CT"),
          eiMri: inputs.medicalImagingEquipment.includes("MRI"),
          eiArteriosclerosis:
            inputs.generalInspectionEquipment.includes("동맥경화 검사기"),
          eiSpirometer: inputs.generalInspectionEquipment.includes(
            "폐기능 검사기(Spirometer)"
          ),
          eiEcg:
            inputs.generalInspectionEquipment.includes("ECG (Cardionet-S)"),
          eiPacs: inputs.medicalImageProcessingUnit.includes("PACS System"),
          eiRemoteImageReading:
            inputs.medicalImageProcessingUnit.includes("원격영상판독"),
          eiName: name,
          eiHospitalName: hospitalName,
          eiWorkArea: region,
          eiCellphone: tel,
          eiEmail: email,
          eiEtc: message,
        },
      });

      if (data.createEstimateInquiry) {
        // alert("견적 문의 완료");
        handleCancel();
      }
    } catch (e) {}
  }, [inputs]);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);
  return (
    <Wrapper>
      <MainText
        text={`온라인 견적 문의를 주시면\n최대한 빠르게 답변드리겠습니다.`}
        subText={
          "온라인 견적 문의는 본 시스템을 통해 \n접수된 건에 한하여 정식 검토되며 최대한 빠르게 \n 기재해주신 연락처로 답변드리고 있습니다."
        }
      />
      <IntroContainer>
        <Title>서비스 소개</Title>
      </IntroContainer>
      <ServiceImage />
      <DownBox>
        <DownLoadButton>
          히포크라테스 브로슈어 다운
          <IoArrowDownCircleOutline style={{ marginLeft: 8 }} />
        </DownLoadButton>
      </DownBox>
      <SubmitContainer>
        <Title>온라인 견적 작성</Title>
        <Line />
        <StyledInput
          REQUIRED
          label="진료과 입력"
          name="medicalDepartment"
          value={inputs.medicalDepartment}
          onChange={onChangeInputs}
          placeholder="진료과를 입력해주세요 (내과 / 외과 / 이비인후과 등)"
        />
        <StyledInput
          REQUIRED
          label="PC사용 대수"
          name="usePCCount"
          type="number"
          value={inputs.usePCCount}
          onChange={onChangeInputs}
          placeholder="병원에서 사용하는 PC 수를 입력하세요"
        />
        <StyledCheckBox
          label="의료영상장비"
          name="medicalImagingEquipment"
          checkBoxList={medicalImagingEquipmentList}
          onChange={handleChecklist}
          values={inputs.medicalImagingEquipment}
        />
        <StyledCheckBox
          label="일반검사장비"
          name="generalInspectionEquipment"
          checkBoxList={generalInspectionEquipmentList}
          values={inputs.generalInspectionEquipment}
          onChange={handleChecklist}
        />
        <StyledCheckBox
          label="의료영상 처리장치"
          name="medicalImageProcessingUnit"
          checkBoxList={medicalImageProcessingUnitList}
          values={inputs.medicalImageProcessingUnit}
          onChange={handleChecklist}
        />
        <StyledInput
          REQUIRED
          label="요청자 이름"
          name="name"
          value={inputs.name}
          onChange={onChangeInputs}
          placeholder="이름을 입력하세요"
        />
        <StyledInput
          REQUIRED
          label="병/의원명"
          name="hospitalName"
          value={inputs.hospitalName}
          onChange={onChangeInputs}
          placeholder="운영 또는 개원 예정이신 병/의원명을 기입해 주세요"
        />
        <StyledInput
          REQUIRED
          label="근무지역"
          name="region"
          value={inputs.region}
          onChange={onChangeInputs}
          placeholder="근무 지역 또는 개원 예정 지역을 입력해주세요"
        />
        <StyledInput
          REQUIRED
          label="연락처"
          name="tel"
          value={inputs.tel}
          onChange={onChangeInputs}
          placeholder="전화번호를 입력해주세요"
        />
        <StyledInput
          REQUIRED
          label="E-mail"
          name="email"
          value={inputs.email}
          onChange={onChangeInputs}
          placeholder="이메일을 입력해주세요"
        />
        <StyledInput
          label="기타"
          name="message"
          value={inputs.message}
          onChange={onChangeInputs}
          placeholder="기타 요청사항 또는 문의사항을 입력해주세요"
        />
        <AgreeBox
          label={`개인정보 수집 및 이용안내`}
          item="개인정보 수집 및 이용에 대해서 동의합니다.*"
          values={agree}
          onChange={handleAgreeCheck}
        />
        <AgreeBox
          label={`유의사항`}
          item="유의사항 내용을 확인하였습니다.*"
          values={agree}
          onChange={handleAgreeCheck}
        />
      </SubmitContainer>
      <ButtonWrapper>
        <StyledButton
          title="온라인 견적 문의하기"
          $fontColor={colors.blueColor}
          border={`1px solid ${colors.blueColor}`}
          onClick={handleContact}
        />
        <StyledButton title="취소" onClick={handleCancel} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Estimate;
