import React, { useState } from "react";
import {
  Wrapper,
  SideTab,
  TabMenu,
  SideBar,
  HistoryList,
  HistoryRow,
  Year,
  Contents,
} from "./styles";

const History = () => {
  const [active, setActive] = useState(2010);
  return (
    <Wrapper>
      <SideTab>
        <TabMenu $active={active === 2010} onClick={() => setActive(2010)}>
          현재 - 2010
          <SideBar $active={active === 2010} width={84} />
        </TabMenu>
        <TabMenu $active={active === 1980} onClick={() => setActive(1980)}>
          2010 - 1980
          <SideBar $active={active === 1980} width={86} />
        </TabMenu>
      </SideTab>

      <HistoryList>
        {active === 2010 ? (
          <>
            <HistoryRow>
              <Year>2022년</Year>
              <Contents>오스템임플란트에 인수 합병</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2021년</Year>
              <Contents>DRG 인증 완료</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2020년</Year>
              <Contents>청구사전점검시스템 연동 및 업무협약 체결</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2019년</Year>
              <Contents>진료정보교류 시스템 개발</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2018년</Year>
              <Contents>
                아프리카 말라위 대양 누가병원 EMR 공급
                <br />
                질병관리본부 감염병신고 자동연계 시스템
                <br />
                마약류통합관리시스템(NIMS) 자동 연계보고 시스템 개발
                <br />
                라운제나 CRM 및 지속관리 시스템 개발
                <br />
              </Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2017년</Year>
              <Contents>
                베트남 하노이 선병원(Sun Medical Center) EMR 공급
                <br />
                베트남 하노이 우리클리닉 EMR 공급
              </Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2016년</Year>
              <Contents>중국 칭다오 JFC국제병원 EMR 공급</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2015년</Year>
              <Contents>Hippocrates(EMR) 출시</Contents>
            </HistoryRow>
          </>
        ) : (
          <>
            <HistoryRow>
              <Year>2010년</Year>
              <Contents>오스템임플란트에 인수 합병</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>2000년</Year>
              <Contents>DRG 인증 완료</Contents>
            </HistoryRow>

            <HistoryRow>
              <Year>1986년</Year>
              <Contents>회사 설립</Contents>
            </HistoryRow>
          </>
        )}
      </HistoryList>
    </Wrapper>
  );
};

export default React.memo(History);
