import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 60px;
`;

export const Image = styled.img`
  width: 100%;
  margin-top: -30px;
  margin-bottom: 30px;
  height: 174px;
  object-fit: cover;
`;

export const Contents = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 18px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
`;
