import Router from "./Router";
import GNB from "./components/layouts/GNB";
import GlobalStyles from "./styles/GlobalStyles";

function App() {
  return (
    <>
      <GlobalStyles />
      <GNB />
      <Router />
    </>
  );
}

export default App;
