import React from "react";
import { Wrapper, Section, Company, SmallText, CompanyName } from "./styles";

const Footer = () => {
  return (
    <Wrapper>
      <Section>
        <Company>Copyright ⓒ Medical Soft | All right reserved.</Company>
        <CompanyName>(주) 메디컬소프트</CompanyName>
        <SmallText>
          서울시 금천구 가산디지털2로 123 월드메르디앙2차 707호
        </SmallText>
        <SmallText>TEL. 02-2025-4333 / FAX. 02-2025-4337</SmallText>
        <SmallText>사업자등록번호 106-86-02713</SmallText>
      </Section>
    </Wrapper>
  );
};

export default Footer;
