import { gql } from "@apollo/client";

export const CREATE_GENERAL_INQUIRY = gql`
  mutation CreateGeneralInquiry(
    $title: String!
    $name: String!
    $hospitalName: String!
    $workArea: String!
    $cellphone: String!
    $email: String!
    $text: String
  ) {
    createGeneralInquiry(
      title: $title
      name: $name
      hospitalName: $hospitalName
      workArea: $workArea
      cellphone: $cellphone
      email: $email
      text: $text
    )
  }
`;
