import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Title, Wrapper, Date, MoreContainer } from "./styles";
import { useQuery } from "@apollo/client";
import { SEE_PLATFORM_NOTICE } from "../../../../graphql/Support/query";
import { useCookies } from "react-cookie";
import PageNation from "../../../layouts/PageNation";

const NoticeBoard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [take, setTake] = useState(7);
  const [cursor, setCursor] = useState(0);
  const [noticeId, setNoticeId] = useState(0);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["pnId"]);

  const { loading, error, data } = useQuery(SEE_PLATFORM_NOTICE, {
    variables: {
      orderBy: "desc",
      year: 0,
      take: take,
      cursor: cursor,
    },
  });

  // if (loading) return null;
  // if (error) return console.log(error.message);
  // if (!data) return null;

  
  const noticeList = data?.seePlatformNotice?.platformNotice;
  const noticeListLength = data?.seePlatformNotice?.totalLength;

  const handleClickPage = (page) => {
    setCurrentPage(page);
    if (page !== 1) {
      setCursor((page - 1) * take);
    } else {
      setCursor(0);
    }
  };

  const dateFormat = (date) => {
    const index = date?.indexOf("T");
    if (index !== -1) {
      return date.slice(0, index);
    }
    return date;
  };

  return (
    <Wrapper>
      {noticeList?.map((item, index) => (
        <Container
          key={`noticeDB` + index}
          onClick={() => {
            setNoticeId(item?.pn_id);
            navigate(`/support/notice`);
            setCookie("pnId", item?.pn_id, { path: "/" });
          }}
        >
          <Title>{item.pn_title}</Title>
          <Date>{dateFormat(item?.pn_createdAt)}</Date>
        </Container>
      ))}
      <PageNation
        TAKE_LIST
        takeOption={[8, 16, 24]}
        currentPage={currentPage}
        take={take}
        setTake={setTake}
        // totalLength={noticeList.length}
        totalLength={noticeListLength}
        handleClickPage={handleClickPage}
      />
    </Wrapper>
  );
};

export default NoticeBoard;
