import { gql } from "@apollo/client";

export const SEE_HPMAIN_IMG = gql`
  query SeeHPMainImg {
    seeHPMain {
      hm_url
    }
  }
`;
export const SEE_COMPANY_INFO = gql`
  query seeCompanyInfo {
    seeCompanyInfo {
      hi_url
    }
  }
`;