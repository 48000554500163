import { gql } from "@apollo/client";

export const SEE_PLATFORM_NOTICE = gql`
  query SeePlatformNotice(
    $orderBy: String
    $year: Int
    $take: Int
    $cursor: Int
  ) {
    seePlatformNotice(
      orderBy: $orderBy
      year: $year
      take: $take
      cursor: $cursor
    ) {
      platformNotice {
        pn_id
        pn_text
        pn_title
        pn_createdAt
      }
      totalLength
    }
  }
`;
export const SEE_PLATFORM_NOTICE_DETAIL = gql`
query SeePlatformNoticeDetail($pnId: Int!) {
  seePlatformNoticeDetail(pn_id: $pnId) {
    pnAttached {
      pna_fileSize
      pna_fileType
      pna_id
      pna_url
    }
    pn_adminCreatorName
    pn_createdAt
    pn_id
    pn_text
    pn_title
    pn_adminCreatorRank
  }
}
`;



