import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const Label = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;
export const CheckList = styled.div`
  border-bottom: 1px solid ${colors.lightGrayColor};
`;
