import styled, { css } from "styled-components";
import colors from "../../../styles/colors";

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -100;
  background-color: ${colors.blackColor};
  opacity: 0;
  transition: opacity 0.8s ease-in-out;

  ${({ open }) =>
    open &&
    css`
      z-index: 100;
      opacity: 0.6;
    `};
`;
export const Container = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 100;
  top: 0;
  right: -100%;
  width: 80%;
  height: 100%;
  overflow: hidden;
  display: flex;
  background-color: ${colors.whiteColor};
  opacity: 1;
  transition: 0.4s;

  ${({ open }) =>
    open &&
    css`
      right: 0%;
    `}
`;

export const Header = styled.div`
  width: 100%;
  padding: 18px;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
`;

export const CloseBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 10;
`;

export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
`;
export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
`;
export const ItemBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 18px;
  border: 0px solid ${colors.lightGrayColor};
  border-bottom-width: 1px;
`;
export const ItemTitle = styled.span``;
export const SubItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightGrayColor};
`;
export const SubItem = styled.div`
  padding: 18px 38px;
`;
