import { styled } from "styled-components";

export const Wrapper = styled.div``;
export const Container = styled.div`
  padding-top: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 0px;
  margin-top: 30px;
  justify-content: space-around;
`;
