import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
`;

export const LogoBox = styled.img``;
export const Hamburger = styled.div`
  z-index: 10;
`;
export const Menu = styled.div``;

export const MenuItem = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  background-color: red;
`;
export const MenuSubContainer = styled.div``;
export const MenuSubItem = styled.div``;
