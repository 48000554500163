import { styled, css } from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 18px;
  flex-direction: column;
  margin-bottom: 60px;
  min-height: 50vh;
`;
export const SideTab = styled.div`
  display: flex;
`;
export const TabMenu = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.grayFontColor};
  padding-bottom: 4px;
  font-size: 18px;
  margin-bottom: 12px;
  margin-right: 18px;
  ${({ $active }) =>
    $active &&
    css`
      color: ${colors.blackColor};
      font-weight: bold;
    `};
`;
export const SideBar = styled.div`
  width: 0px;
  height: 4px;
  bottom: -2px;
  transition: 0.3s ease-out;

  ${({ $active }) =>
    $active &&
    css`
      width: ${({ width }) => width}px;
      background-color: ${colors.blueColor};
    `};
`;
export const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HistoryRow = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 1.2;
  align-items: flex-start;
  margin-bottom: 24px;
  border: 0px solid ${colors.borderGrayColor};
  border-width: 1px 0px 1px 0px;
  padding: 20px 0px;
  & + & {
    border-top-width: 0px;
    padding-top: 0px;
  }
`;
export const Year = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
`;
export const Contents = styled.div`
  font-weight: 400;
  line-height: 1.4;
  font-size: 16px;
`;
