import { styled } from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0px;
  border: 0px solid ${colors.borderGrayColor};
  border-bottom-width: 1px;
  border-top-width: 1px;
  &:first-child {
    border: 0px solid ${colors.blackColor};
    border-top-width: 2px;
  }
  & + & {
    border-top-width: 0px;
  }
`;
export const IconBox = styled.div`
  margin-right: 40px;
`;
export const Icon = styled.img``;
export const Contents = styled.div`
  font-size: 15px;
  word-break: keep-all;
  line-height: 1.4;
`;
export const Title = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
`;
export const Text = styled.div`
  line-height: 1.4;
  font-weight: normal;
`;
