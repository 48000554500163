import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.blackColor};
  margin-bottom: 20px;
`;
export const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Contents = styled.div`
  font-family: "AppleSdNeoRegular";
  line-height: 1.4;
  color: ${colors.grayFontColor};
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrayColor};
`;
