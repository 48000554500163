import { gql } from "@apollo/client";

export const CREATE_ESTIMATE_INQUIRY = gql`
  mutation CreateEstimateInquiry(
    $eiMedicalSub: String!
    $eiPcCount: String!
    $eiName: String!
    $eiHospitalName: String!
    $eiWorkArea: String!
    $eiCellphone: String!
    $eiEmail: String!
    $eiEtc: String
    $eiRdr: Boolean
    $eiCr: Boolean
    $eiXray: Boolean
    $eiCArm: Boolean
    $eiMammography: Boolean
    $eiUltrasonicWave: Boolean
    $eiEndoscope: Boolean
    $eiCt: Boolean
    $eiMri: Boolean
    $eiArteriosclerosis: Boolean
    $eiSpirometer: Boolean
    $eiEcg: Boolean
    $eiPacs: Boolean
    $eiRemoteImageReading: Boolean
  ) {
    createEstimateInquiry(
      ei_medicalSub: $eiMedicalSub
      ei_pcCount: $eiPcCount
      ei_name: $eiName
      ei_hospitalName: $eiHospitalName
      ei_workArea: $eiWorkArea
      ei_cellphone: $eiCellphone
      ei_email: $eiEmail
      ei_etc: $eiEtc
      ei_RDR: $eiRdr
      ei_CR: $eiCr
      ei_XRAY: $eiXray
      ei_CArm: $eiCArm
      ei_Mammography: $eiMammography
      ei_ultrasonicWave: $eiUltrasonicWave
      ei_endoscope: $eiEndoscope
      ei_CT: $eiCt
      ei_MRI: $eiMri
      ei_arteriosclerosis: $eiArteriosclerosis
      ei_spirometer: $eiSpirometer
      ei_ECG: $eiEcg
      ei_PACS: $eiPacs
      ei_remoteImageReading: $eiRemoteImageReading
    )
  }
`;
