import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  width: 100%;
  padding: 18px;
  margin-top: 90px;
  background: ${colors.footerColor};
`;
export const Section = styled.section`
  width: 100%;
  max-width: 1144px;
  font-size: 12px;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const Company = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;
export const CompanyName = styled.div`
  margin-bottom: 12px;
`;
export const SmallText = styled.div`
  white-space: normal;
  word-break: keep-all;
  margin-right: 40px;
`;
