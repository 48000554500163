import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
export const Label = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
`;
export const Star = styled.span`
  color: ${colors.deepOrangeColor};
`;
export const Input = styled.input`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrayColor};
  font-size: 16px;

  &::placeholder {
    color: ${colors.grayFontColor};
    font-size: 15px;
  }
`;
