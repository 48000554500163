import * as N from './Notice.styles';
// import PageIntro from "../../components/layout/PageIntro";
import { useQuery } from '@apollo/client';
import { SEE_PLATFORM_NOTICE_DETAIL } from '../../graphql/Support/query';
import { useCookies } from 'react-cookie';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NoticeDetail = () => {
  const [cookies] = useCookies(['pnId']);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies || !cookies['pnId']) {
      navigate('/support');
    }
  }, [cookies, navigate]);

  const { loading, data } = useQuery(SEE_PLATFORM_NOTICE_DETAIL, {
    variables: {
      pnId: parseInt(cookies['pnId'], 10),
    },
  });

  const noticeData = data?.seePlatformNoticeDetail;
  const noticeAttached = noticeData?.pnAttached;

  const filename = (url) => {
    const filetype = url.split('.');
    const extension = filetype[filetype.length - 1];
    const parts = url.split('-');
    let name = parts[parts.length - 1].split('.')[0];

    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(name)) {
      if (name?.length > 7) {
        name = name.substring(0, 7) + '...';
      }
    } else {
      if (name?.length > 9) {
        name = name.substring(0, 9) + '...';
      }
    }

    return name + '.' + extension;
  };

  const dateFormat = (date) => {
    const originalDate = new Date(date);
    const formattedDate = `${originalDate?.getFullYear()}-${String(originalDate.getMonth() + 1).padStart(2, '0')}-${String(originalDate?.getDate()).padStart(2, '0')} T${String(
      originalDate?.getHours()
    ).padStart(2, '0')}:${String(originalDate?.getMinutes()).padStart(2, '0')}`;
    return formattedDate;
  };

  return (
    <N.Wrapper>
      {/* {!loading && ( */}
      <N.Contents>
        <N.TitleWrap>
          <N.Title>{noticeData?.pn_title}</N.Title>
          {noticeData && <N.SubTitle>{`${noticeData?.pn_adminCreatorName} ${noticeData?.pn_adminCreatorRank} ${dateFormat(noticeData?.pn_createdAt)}`}</N.SubTitle>}
        </N.TitleWrap>
        <N.NoticeWrap>
          <N.Text>{noticeData?.pn_text}</N.Text>
        </N.NoticeWrap>
        {noticeAttached?.length > 0 && (
          <N.Attachment>
            <N.GrayText>{noticeAttached?.length}개의 첨부파일</N.GrayText>

            <N.FileWrap>
              {noticeAttached.map((item, index) => (
                <N.File key={index}>
                  <N.Thumbnail src={item.pna_url} />
                  <N.FileInfo>
                    <N.FileName>{filename(item.pna_url)}</N.FileName>
                    <N.FileSize>{Math.round((item.pna_fileSize / (1024 * 1024)) * 10) / 10} MB</N.FileSize>
                  </N.FileInfo>
                </N.File>
              ))}
            </N.FileWrap>
          </N.Attachment>
        )}
      </N.Contents>
      {/* )} */}
    </N.Wrapper>
  );
};

export default NoticeDetail;
