import { gql } from "@apollo/client";

export const SEE_SERVICE = gql`
query SeeService($hsiServiceType: String!, $hsiDetailTabName: String!, $serviceType: String!, $detailTabName: String!) {
  seeServiceImg(hsi_serviceType: $hsiServiceType, hsi_detailTabName: $hsiDetailTabName) {
    hsi_img
  }
  seeServiceContent(serviceType: $serviceType, detailTabName: $detailTabName) {
    title
    detailTextCount
    serviceDetail
  }
  seeHPServiceDetail {
    hsd_url
  }
}
`;
export const SEE_HP_SERVICE_MAIN = gql`
query SeeHPServiceMain {
  seeHPServiceMain {
    hsm_url
  }
}
`;
