import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 18px;
  white-space: pre-line;
  margin-bottom: 60px;
`;
const Text = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
`;
const SubText = styled.span`
  display: block;
  margin-top: 30px;
`;

const MainText = ({ text = "", subText = "" }) => {
  return (
    <Wrapper>
      <Text>{text}</Text>
      <SubText>{subText}</SubText>
    </Wrapper>
  );
};

export default MainText;
