import { styled } from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0px;
  border: 0px solid ${colors.borderGrayColor};
  border-bottom-width: 1px;
`;
export const Title = styled.div`
  margin-bottom: 12px;
  /* font-weight: bold; */
`;
export const Date = styled.div`
  font-weight: medium;
`;
