import React from "react";
import map from "../../../../assets/Home/map.svg";
import { Wrapper, Title, Contents, MapBox, Image, AddressText } from "./styles";

const Address = () => {
  return (
    <Wrapper>
      <Title>위치</Title>
      <Contents>
        <MapBox>
          <Image src={map} />
        </MapBox>

        <AddressText>
          서울시 금천구 가산디지털2로 123 <br /> 월드메르디앙2차 707호
        </AddressText>
      </Contents>
    </Wrapper>
  );
};

export default React.memo(Address);
