import { styled, css } from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 18px;
  flex-direction: column;
  min-height: 50vh;
`;
export const SideTab = styled.div`
  display: flex;
`;
export const TabMenu = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.grayFontColor};
  font-size: 18px;
  margin-bottom: 20px;
  margin-right: 18px;
  ${({ $active }) =>
    $active &&
    css`
      color: ${colors.blackColor};
      font-weight: bold;
    `};
`;

export const SideBar = styled.div`
  width: 0px;
  height: 4px;
  bottom: -2px;
  transition: 0.3s ease-out;

  ${({ $active }) =>
    $active &&
    css`
      width: ${({ width }) => width}px;
      background-color: ${colors.blueColor};
    `};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.blackColor};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
