import styled from "styled-components";
import colors from "../../styles/colors";


export const Image = styled.img`
  width: 100%;
  margin-top: -30px;
  margin-bottom: 30px;
  height: 174px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Contents = styled.div`
  width: 100%;
  padding: 0 18px;
  margin: 0 auto 0;
`;
export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const NoticeWrap = styled.div`
  border-top: 1px solid ${colors.borderGrayColor};
  padding: 30px 0;
`;
export const TitleWrap = styled.div`
  padding: 30px 0;
`;
export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const SubTitle = styled.div`
  font-size: 15px;
  line-height: 19px;
`;
export const Text = styled.div`
  font-size: 16px;
  line-height: 26px;
  min-height: 470px;
`;
export const Attachment = styled.div`
  padding: 40px 0 0;
`;
export const GrayText = styled.div`
  color: #b1b1b1;
  font-size: 14px;
  margin-bottom: 20px;
`;
export const FileWrap = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;
export const File = styled.div`
  min-width: 164px;
  flex: 1;
  height: 52px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid ${colors.borderGrayColor};
  border-radius: 4px;
  overflow: hidden;
`;
export const Thumbnail = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 4px 0px 0px 4px;
  object-fit: cover;
`;
export const FileInfo = styled.div`
  flex: 1;
  padding: 0 12px;
`;
export const FileName = styled.div``;
export const FileSize = styled.div`
  color: #b1b1b1;
`;
