import { styled } from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div``;

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-direction: row;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.borderGrayColor};
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const Title = styled.div`
  width: 28%;
  font-weight: bold;
`;
export const RightWrapper = styled.div`
  display: flex;
  width: 72%;
  flex-direction: column;
`;
export const RightTitle = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;
export const RightSubText = styled.div`
  margin-bottom: 12px;
`;
export const NavButton = styled.div`
  display: flex;
  width: 54%;
  align-items: center;
`;
export const NavText = styled.span`
  display: block;
  margin-right: 4px;
`;
