import React, { useRef, useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import colors from "../../../styles/colors";
import pageArrow from "../../../assets/pageNation/pageArrow.svg";
import pageDoubleArrow from "../../../assets/pageNation/pageDoubleArrow.svg";
import selectFillArrow from "../../../assets/pageNation/selectFillArrow.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;
const FlexBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Wrap = styled.div`
  display: flex;
  align-items: center;
`;
const ArrowBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.borderGrayColor};
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightBgColor};
  }

  &:active {
    opacity: 0.7;
  }
`;
const Text = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 15)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  color: ${({ $fontColor }) => ($fontColor ? $fontColor : colors.blackColor)};
  margin: ${({ $margin }) => ($margin ? $margin : 0)};
  padding-top: 2px;
`;
const PageWrapper = styled.div`
  width: 66px;
  position: relative;
  margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;
const SelectBox = styled.div`
  height: 32px;
  color: ${colors.blackColor};
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.borderGrayColor};
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightBgColor};
  }

  &:active {
    opacity: 0.5;
  }
`;
const OptionBox = styled.div`
  width: 100%;
  max-height: 380px;
  padding: 10px 0;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 30px;
  overflow-y: scroll;
  border: 1px solid ${colors.borderGrayColor};
  background-color: ${colors.whiteColor};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const OptionText = styled.div`
  color: ${colors.grayFontColor};
  padding: 12px;
  cursor: pointer;

  &:hover {
    color: ${colors.blackColor};
    background-color: ${colors.lightBgColor};
  }
`;
const Image = styled.img`
  width: ${({ width }) => (width ? width : 15)}px;
  height: ${({ height }) => (height ? height : 15)}px;
  margin: ${({ $margin }) => ($margin ? $margin : 0)};
  ${({ $rotated }) =>
   $rotated &&
    css`
      transform: rotate(180deg);
    `};
`;

const PageNation = ({
  TAKE_LIST,
  totalLength = 1,
  take = 16,
  currentPage = 1,
  handleClickPage = () => null,
  setTake = () => null,
  takeOption = [16, 32, 48],
}) => {
  const [optionVisible, setOptionVisible] = useState(false);
  const [boxHeight, setBoxHeight] = useState(0);
  const [options, setOptions] = useState([]);
  const pageSelectRef = useRef(null);
  const pageOptionRef = useRef(null);

  const lastPage = Math.ceil(totalLength / take);

  const clickOption = useCallback((page) => {
    handleClickPage(page);
    setOptionVisible(false);
  }, []);

  const handleClickOutside = useCallback(
    ({ target }) => {
      let pageSelectRefCurrent =
        pageSelectRef.current && pageSelectRef.current.contains(target);
      let pageOptionRefCurrent =
        pageOptionRef.current && pageOptionRef.current.contains(target);

      if (!pageSelectRefCurrent && !pageOptionRefCurrent)
        setOptionVisible(false);
    },
    [pageSelectRef, pageOptionRef]
  );

  useEffect(() => {
    let option = [];
    if (totalLength !== 0) {
      for (let i = 1; i <= lastPage; i++) {
        option.push(i);
      }
      setOptions(option);
    } else {
      option.push(1);
    }
    setBoxHeight(options.length * 50);
  }, [lastPage, options.length, totalLength]);

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  });

  return (
    <Wrapper>
      <FlexBox>
        <Wrap>
          <ArrowBox onClick={() => handleClickPage(1)}>
            <Image src={pageDoubleArrow} />
          </ArrowBox>
          <ArrowBox
            onClick={() =>
              currentPage > 1 ? handleClickPage(currentPage - 1) : null
            }
          >
            <Image src={pageArrow} />
          </ArrowBox>
        </Wrap>

        <Wrap>
          <Text
            $fontColor={colors.grayFontColor}
            fontWeight={500}
            $margin="0 12px"
          >
            페이지
          </Text>
          <PageWrapper $margin="0 12px 0 0">
            <SelectBox
              onClick={() => setOptionVisible(!optionVisible)}
              ref={pageSelectRef}
            >
              {currentPage}
              <Image src={selectFillArrow} width={9} height={9} />
            </SelectBox>
            {optionVisible && options.length !== 0 && (
              <OptionBox boxHeight={boxHeight} ref={pageOptionRef}>
                {options.map((page) => (
                  <OptionText
                    key={`option-${page}`}
                    onClick={() => clickOption(page)}
                  >
                    {page}
                  </OptionText>
                ))}
              </OptionBox>
            )}
          </PageWrapper>
        </Wrap>

        <Wrap>
          <ArrowBox
            onClick={() =>
              lastPage > 1 && currentPage < lastPage
                ? handleClickPage(currentPage + 1)
                : null
            }
          >
            <Image src={pageArrow} $rotated />
          </ArrowBox>
          <ArrowBox
            onClick={() => (lastPage > 1 ? handleClickPage(lastPage) : null)}
          >
            <Image src={pageDoubleArrow} $rotated />
          </ArrowBox>
        </Wrap>
      </FlexBox>
    </Wrapper>
  );
};

export default React.memo(PageNation);
