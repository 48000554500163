import { styled } from "styled-components";
import colors from "../../../../styles/colors";

export const Container = styled.div`
  display: flex;
  position: relative;
  height: 224px;
  margin-top: 20px;
  margin-bottom: 30px;

  .swiper-pagination-horizontal {
    font-size: 12px;
    color: ${colors.whiteColor};
    position: absolute;
    bottom: 20px;
    right: 18px;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;
export const Wrapper = styled.div`
  flex: 1;
  height: 224px;
  width: 100%;
  position: relative;
  cursor: pointer;
`;
export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;
export const HoverBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.3);
`;
export const TextBox = styled.div`
  width: 100%;
  padding: 0 30px;
  color: ${colors.whiteColor};
  position: absolute;
  bottom: 20px;
`;
export const Title = styled.div`
  font-size: 16px;
  margin-bottom: 12px;
`;
export const SubTitleBox = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const SubTitle = styled.div`
  font-size: 16px;
  word-break: keep-all;
  line-height: 1.4;
`;
export const ArrowBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: 4px;
  padding-bottom: 6px;
  background-color: ${colors.cottonColor};
`;
