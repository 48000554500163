import React, { useEffect, useState } from 'react';
import { Wrapper, Image, CEOContainer, CEOTitle, CEOComment } from './styles';
import MainText from '../../components/public/MainText';
import aboutImage from '../../assets/images/about_banner.jpeg';
import CompanyInfo from '../../components/features/About/CompanyInfo';
import History from '../../components/features/Home/History';
import Address from '../../components/features/Home/Address';
import { useQuery } from '@apollo/client';
import { SEE_COMPANY_INFO } from '../../graphql/Home/query';

const About = () => {
  const [imgData, setImgData] = useState('');
  const { data } = useQuery(SEE_COMPANY_INFO);

  useEffect(() => {
    if (imgData) {
      setImgData(data?.seeCompanyInfo?.hi_url);
    }
  }, [imgData]);

  return (
    <Wrapper>
      <MainText text={`30여년 오직 한길만 걸으며\n병원의 성공과 함께 해 온 메디칼소프트`} subText={'소프트웨어를 통해 꿈꾸는 가치를 실현합니다.'} />
      <Image src={imgData} />
      <CEOContainer>
        <CEOTitle>대표이사 인사말</CEOTitle>
        <CEOComment>
          ㈜MedicalSoft는 “소프트웨어를 통한 고객 가치 실현”이라는 경영이념을 바탕으로 고객의 가치를 최우선으로 여기며, 끊임없이 노력하여 고객 여러분과 함께 성장하는 기업이
          되겠습니다. 기업 가치는 고객 여러분의 지속적인 관심과 사랑에 의해 결정됩니다. 오늘의 ㈜MedicalSoft가 이 자리에 있도록 함께해주신 모든 분들께 진심으로 감사드리며, 앞으로도
          여러분 곁을 지킬 것을 약속 드립니다.
          <br />
          <br />
          의료 분야 소프트웨어 전문지식과 젊고 참신한 인재가 결합된 ㈜MedicalSoft는 고객 여러분의 가치 창조 및 실현을 위해 최선을 다할 것이며, 고객의 목소리에 늘 세심한 주의를
          기울이는 기업으로서 책임과 의무를 다하겠습니다.
          <br />
          <br />
          또한, ㈜MedicalSoft는 전자 의무기록 (EMR) 및 다양한 서비스를 통해 구축된 의료 정보 서비스 시스템을 적극 활용하여 효율적이고 스마트한 병원 업무를 지원할 것입니다. 항상
          발전하는 ㈜MedicalSoft가 되어 고객 여러분의 믿음에 부응하는 기업이 될 것을 약속 드립니다.
        </CEOComment>
      </CEOContainer>
      <CompanyInfo />
      <History />
      <Address />
    </Wrapper>
  );
};

export default About;
