import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 60px;
`;

export const Image = styled.img`
  width: 100%;
  margin-top: -30px;
  margin-bottom: 30px;
  height: 174px;
`;
