import React from "react";
import styled from "styled-components";
import colors from "../../../../styles/colors";

const Button = styled.button`
  width: 46%;
  height: 60px;
  font-size: 16px;
  color: ${({ $fontColor }) => $fontColor};
  margin: ${({ $margin }) => $margin};
  border: ${({ $border }) => $border};
  background-color: ${({ $bgColor }) =>$bgColor};
  transition: 0.2s;

  &:hover {
    color: ${({ $bgColor }) =>$bgColor};
    background-color: ${({ $fontColor }) => $fontColor};
  }

  &:active {
    opacity: 0.7;
  }
`;

const StyledButton = ({
  title,
  fontColor = colors.grayColor,
  margin = 0,
  border = `1px solid ${colors.grayColor}`,
  bgColor = colors.whiteColor,
  onClick = () => null,
}) => {
  return (
    <Button
      $fontColor={fontColor}
      $margin={margin}
      $border={border}
      $bgColor={bgColor}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export default React.memo(StyledButton);
