import React from "react";
import {
  Wrapper,
  Container,
  NavButton,
  NavText,
  RightSubText,
  RightTitle,
  RightWrapper,
  TitleWrapper,
  Title,
  Line,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { IoChevronForward } from "react-icons/io5";

const IntroData = [
  {
    title: "공지",
    subTitle: "코로나 19 관려 건강보험 수가 연장 적용",
    subText: "아래 같이 코로나-19 건강보험 수가가 연장되었으니 참고하세요.",
    navTitle: "공지 전체 보기",
    where: "support",
  },
  {
    title: "인사말",
    subTitle:
      "30여년 병원의 성공과 함께 해 온 메디칼소프트를 찾아주셔서 감사합니다.",
    navTitle: "대표이사 인사말",
    where: "about",
  },
  {
    title: "서비스",
    subTitle: "총 13여개의 서비스",
    navTitle: "서비스 전체보기",
    where: "service",
  },
  {
    title: "견적문의",
    subTitle: "독보적인 경험을 바탕으로 한 효율적이고 스마트한 EMR 차트",
    navTitle: "온라인 견적 문의",
    where: "estimate",
  },
];

const IntroSection = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      {IntroData.map((item, index) => (
        <Container key={`IntroSection` + index}>
          <TitleWrapper>
            <Title>{item.title}</Title>
            <RightWrapper>
              <RightTitle>{item.subTitle}</RightTitle>
              <RightSubText>{item.subText}</RightSubText>
              <NavButton onClick={() => navigate("/" + item.where)}>
                <NavText>{item.navTitle}</NavText>
                <IoChevronForward />
              </NavButton>
            </RightWrapper>
          </TitleWrapper>
          <Line />
        </Container>
      ))}
    </Wrapper>
  );
};

export default IntroSection;
