import { styled } from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  height: 60vh;
  position: relative;
  width: 100%;

  .swiper-pagination-horizontal {
    font-size: 12px;
    color: ${colors.whiteColor};
    position: absolute;
    bottom: 12px;
    right: 0px;
    right: calc((100vw - 50vw));
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;

export const BannerImage = styled.div`
  width: 100%;
  height: 60vh;
  background-color: rgba(0, 0, 0, 0.8);
`;
export const Image = styled.img`
  height: 60vh;
  object-fit: contain;
`;
export const Contents = styled.div`
  max-width: 100%;
  line-height: 1.4;
  color: ${colors.whiteColor};
  position: absolute;
  left: calc((100vw - 1144px) / 2);
  bottom: 100px;
  margin: 0 auto;
  z-index: 2;
`;
export const Title = styled.span`
  font-size: 26px;
`;
export const FlowBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 25vh;
  color: ${colors.whiteColor};
  line-height: 1.4;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
export const FlowTitle = styled.span`
  font-size: 26px;
  color: ${colors.whiteColor};
  line-height: 1.4;
  text-align: center;
`;

export const Line = styled.div`
  width: 40px;
  height: 2px;
  margin: 20px 0;
  background-color: ${colors.whiteColor};
`;
export const Subtitle = styled.span`
  font-size: 15px;
`;
export const ArrowBox = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  padding: 0px 18px;
  bottom: 34px;
`;
export const SlideArrow = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.whiteColor};
  cursor: pointer;
  & + & {
    margin-left: 8px;
  }
`;
