import React, { useState } from "react";
import { IoChevronDown, IoChevronUp, IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import {
  Background,
  CloseBox,
  Container,
  Header,
  ItemBox,
  ItemContainer,
  ItemTitle,
  ListContainer,
  SubItem,
  SubItemContainer,
} from "./styles";

const MenuListModal = ({ closeMenu = () => null, isSideBarOpen }) => {
  const [isOpen, setIsOpen] = useState("");
  const navigate = useNavigate();

  const toggleMenu = (title) => {
    if (isOpen === title) {
      setIsOpen("");
    } else {
      setIsOpen(title);
    }
  };

  const subList = {
    about: ["회사소개"],
    service: [
      "서비스 목록",
      "Hippocrates",
      "Hippocrates Check",
      "Hippocrates Image",
      "Hippocrates LAB",
      "Hippocrates e-Sign",
      "Hippocrates Desk",
      "Hippocrates Cloud Service",
      "Hippocrates Web DID",
      "Hippocrates Medical Checkup",
      "Hippocrates Pen Chart",
      "라온제나 CRM",
      "기타서비스",
    ],
    estimate: ["온라인 견적문의"],
    support: ["고객센터"],
  };
  return (
    <>
      <Background open={isSideBarOpen * 1} />
      <Container open={isSideBarOpen * 1}>
        <Header>
          <Logo height="18px" width="140px" />
          <CloseBox>
            <IoClose size={20} onClick={closeMenu} style={{ zIndex: 10 }} />
          </CloseBox>
        </Header>
        <ListContainer>
          <ItemContainer>
            <ItemBox onClick={() => toggleMenu("회사소개")}>
              <ItemTitle>회사소개</ItemTitle>
              {isOpen === "회사소개" ? (
                <IoChevronUp size={20} />
              ) : (
                <IoChevronDown size={20} />
              )}
            </ItemBox>
            {isOpen === "회사소개" && (
              <SubItemContainer>
                {subList.about.map((item, index) => (
                  <SubItem
                    onClick={() => {
                      navigate("/about");
                      closeMenu();
                      return;
                    }}
                    key={"about" + index}
                  >
                    {item}
                  </SubItem>
                ))}
              </SubItemContainer>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemBox onClick={() => toggleMenu("서비스")}>
              <ItemTitle>서비스</ItemTitle>
              {isOpen === "서비스" ? (
                <IoChevronUp size={20} />
              ) : (
                <IoChevronDown size={20} />
              )}
            </ItemBox>
            {isOpen === "서비스" && (
              <SubItemContainer>
                {subList.service.map((item, index) => (
                  <SubItem
                    onClick={() => {
                      let path;

                      switch (item) {
                        case "서비스 목록":
                          navigate("/service");
                          break;
                        case "Hippocrates":
                          navigate("/service/detail/emr");
                          break;
                        case "Hippocrates Check":
                          navigate("/service/detail/check");
                          break;
                        case "Hippocrates Image":
                          navigate("/service/detail/image");
                          break;
                        case "Hippocrates LAB":
                          navigate("/service/detail/lab");
                          break;
                        case "Hippocrates e-Sign":
                          navigate("/service/detail/eSign");
                          break;
                        case "Hippocrates Desk":
                          navigate("/service/detail/desk");
                          break;
                        case "Hippocrates Cloud Service":
                          navigate("/service/detail/cloud");
                          break;
                        case "Hippocrates Web DID":
                          navigate("/service/detail/did");
                          break;
                        case "Hippocrates Medical Checkup":
                          navigate("/service/detail/checkUp");
                          break;
                        case "Hippocrates Pen Chart":
                          navigate("/service/detail/chart");
                          break;
                        case "라온제나 CRM":
                          navigate("/service/detail/crm");
                          break;
                        case "기타서비스":
                          navigate("/service/detail/etc");
                          break;
                        default:
                          navigate("/service");
                      }
                      closeMenu();
                    }}
                    key={"service" + index}
                  >
                    {item}
                  </SubItem>
                ))}
              </SubItemContainer>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemBox onClick={() => toggleMenu("온라인 견적")}>
              <ItemTitle>온라인 견적</ItemTitle>
              {isOpen === "온라인 견적" ? (
                <IoChevronUp size={20} />
              ) : (
                <IoChevronDown size={20} />
              )}
            </ItemBox>
            {isOpen === "온라인 견적" && (
              <SubItemContainer>
                {subList.estimate.map((item, index) => (
                  <SubItem
                    onClick={() => {
                      navigate("/estimate");
                      closeMenu();
                      return;
                    }}
                    key={"estimate" + index}
                  >
                    {item}
                  </SubItem>
                ))}
              </SubItemContainer>
            )}
          </ItemContainer>
          <ItemContainer>
            <ItemBox onClick={() => toggleMenu("고객센터")}>
              <ItemTitle>고객센터</ItemTitle>
              {isOpen === "고객센터" ? (
                <IoChevronUp size={20} />
              ) : (
                <IoChevronDown size={20} />
              )}
            </ItemBox>
            {isOpen === "고객센터" && (
              <SubItemContainer>
                {subList.support.map((item, index) => (
                  <SubItem
                    onClick={() => {
                      navigate("/support");
                      closeMenu();
                      return;
                    }}
                    key={"support" + index}
                  >
                    {item}
                  </SubItem>
                ))}
              </SubItemContainer>
            )}
          </ItemContainer>
        </ListContainer>
      </Container>
    </>
  );
};

export default MenuListModal;
