import React, { memo } from "react";
import { Wrapper, Label, Star, Input } from "./styles";

const StyledInput = ({
  REQUIRED,
  label,
  type = "text",
  name,
  placeholder,
  value,
  onChange,
  maxLength,
}) => {
  return (
    <Wrapper>
      <Label>
        {label}&nbsp;
        {REQUIRED && <Star>*</Star>}
      </Label>
      <Input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

export default memo(StyledInput);
