import React, { useEffect, useState } from "react";
import { Wrapper, Image, Title, Contents } from "../Service/styles";
import MainText from "../../components/public/MainText";
import serviceBanner from "../../assets/images/service_banner.jpeg";
import ServiceList from "../../components/features/Service/ServiceList";
import hippocratesIcon from "../../assets/icons/hippocratesIcon.svg";
import hippocratesCheckIcon from "../../assets/icons/hippocratesCheckIcon.svg";
import hippocratesImageIcon from "../../assets/icons/hippocratesImageIcon.svg";
import hippocratesLABIcon from "../../assets/icons/hippocratesLABIcon.svg";
import hippocratesEsignIcon from "../../assets/icons/hippocratesEsignIcon.svg";
import hippocratesDeskIcon from "../../assets/icons/hippocratesDeskIcon.svg";
import hippocratesCloudIcon from "../../assets/icons/hippocratesCloudIcon.svg";
import hippocratesDIDIcon from "../../assets/icons/hippocratesDIDIcon.svg";
import hippocratesCheckupIcon from "../../assets/icons/hippocratesCheckupIcon.svg";
import hippocratesPenChartIcon from "../../assets/icons/hippocratesPenChartIcon.svg";
import laonzenaIcon from "../../assets/icons/laonzenaIcon.svg";
import etcIcon from "../../assets/icons/etcIcon.svg";
import reservationIcon from "../../assets/icons/reservationIcon.svg";
import didIcon from "../../assets/icons/didIcon.svg";
import insuranceIcon from "../../assets/icons/insuranceIcon.svg";
import operationIcon from "../../assets/icons/operationIcon.svg";
import { useQuery } from "@apollo/client";
import { SEE_HP_SERVICE_MAIN } from "../../graphql/Service/query";

const Service = () => {
  const [imgData, setImgData] = useState('');
  const { data } = useQuery(SEE_HP_SERVICE_MAIN);

  useEffect(() => {
    if (data) {
      setImgData(data?.seeHPServiceMain?.hsm_url);
    }
  }, [data]);
  
  return (
    <Wrapper>
      <MainText
        text={`독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트`}
        subText={
          "최신 IT기술을 접목하여 사용자들의 편의성과 효율을 극대화 하였습니다."
        }
      />
      <Image
            src={imgData}
      />
      <Contents>
        <Title>EMR 차트</Title>
        <ServiceList
          path="/service/detail/emr"
          src={hippocratesIcon}
          title="Hippocrates"
          contents="30여년의 경험과 Know-How로 효율적이고 스마트한 EMR 차트"
        />
        <ServiceList
          path="/service/detail/check"
          src={hippocratesCheckIcon}
          title="Hippocrates Check"
          contents="청구 삭감을 사전에 방지하는 실시간 사전 점검 솔루션"
        />

        <ServiceList
          path="/service/detail/image"
          src={hippocratesImageIcon}
          title="Hippocrates Image"
          contents="다양한 의료 영상장비와 연결하여 EMR차트에 저장하여 관리하는 솔루션"
        />
        <ServiceList
          path="/service/detail/lab"
          src={hippocratesLABIcon}
          title="Hippocrates LAB"
          contents="수탁 검사기관 들과 실시간 연계하여 신속하고 정확하게 검사결과를 관리하는 솔루션"
        />

        <ServiceList
          path="/service/detail/eSign"
          src={hippocratesEsignIcon}
          title="Hippocrates e-Sign"
          contents="진료기록에 공동인증서명을 추가하여 전자의무기록문서로 변환시키는 솔루션"
        />
        <ServiceList
          path="/service/detail/desk"
          src={hippocratesDeskIcon}
          title="Hippocrates Desk"
          contents="모바일과 태블릿, 키오스크를 통한 비-대면 무인, 자동 접수 솔루션"
        />

        <ServiceList
          path="/service/detail/cloud"
          src={hippocratesCloudIcon}
          title="Hippocrates Cloud Service"
          contents="병원 데이터를 아마존 웹서버(AWS)에 안전하게 보관 및 관리하는 서비스"
        />
        <ServiceList
          path="/service/detail/did"
          src={hippocratesDIDIcon}
          title="Hippocrates Web DID"
          contents="대기환자리스트 현황, 음성호출, 병원홍보 등 다양한 미디어 컨텐츠 제공"
        />

        <ServiceList
          path="/service/detail/checkUp"
          src={hippocratesCheckupIcon}
          title="Hippocrates Medical Checkup(건강검진)"
          contents="건강검진 접수에서 검사결과 연동 및 공단 청구까지 실시간 으로 관리하는 솔루션"
        />
        <ServiceList
          path="/service/detail/chart"
          src={hippocratesPenChartIcon}
          title="Hippocrates Pen Chart"
          contents="차트 연동 태블릿 앱 서비스로 각종 동의서 및 문진표 작성 등 다양한 기능 제공 솔루션"
        />

        <ServiceList
          path="/service/detail/crm"
          src={laonzenaIcon}
          title="라온제나 CRM"
          contents="진료예약, 검사예약, 예방접종 등 환자에게 맞춤 메시지 발송 및 관리 솔루션"
        />
        <ServiceList
          path="/service/detail/etc"
          src={etcIcon}
          title="기타서비스"
          contents="실손 보험 간소화, 진료정보교류, 마약류통합관리 등 다양한 부가서비스 제공"
        />
      </Contents>
      <Contents>
        <Title>CLOUD 플랫폼</Title>
        <ServiceList
          path="/service/detail/appointmemt"
          src={reservationIcon}
          title="예약 연동"
          contents={`네이버·카카오에서 예약 연동 기능 \n히포크라테스와 연동`}
        />
        <ServiceList
          path="/service/detail/didMonitor"
          src={didIcon}
          title="DID 모니터 연동"
          contents="오래된 모니터도 기기연결만 가능하다면 DID 모니터 연결 가능"
        />

        <ServiceList
          path="/service/detail/insurance"
          src={insuranceIcon}
          title="실손 보험 청구"
          contents="실손보험 업체와 플랫폼 연동, 플랫폼과 히포크라테스와의 보험 데이터 연동 기능"
        />
        <ServiceList
          path="/service/detail/schedule"
          src={operationIcon}
          title="병원 운영 스케줄 관리"
          contents="예약 기능과 연동시 적용할 휴무·특별일정 등의 병원 운영시간 스케줄 관리 기능"
        />
      </Contents>
    </Wrapper>
  );
};

export default Service;
