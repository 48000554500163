import React from "react";
import { Wrapper, Image } from "./styles";
import MainText from "../../components/public/MainText";
import supportBanner from "../../assets/images/support_banner.jpeg";
import SupportTab from "../../components/features/Support/SupportTab";

const Support = () => {
  return (
    <Wrapper>
      <MainText
        text={"고객분들의 목소리에\n늘 세심한 주의를 기울이겠습니다."}
        subText={
          "필요한 메뉴를 선택하시어 연락주시면\n최대한 빠르게 답변드리겠습니다."
        }
      />
      <Image src={supportBanner} />
      <SupportTab />
    </Wrapper>
  );
};

export default Support;
