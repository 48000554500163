import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import colors from "./colors";
import AppleSDGothicNeoB from "./font/AppleSDGothicNeoB.ttf";
import AppleSDGothicNeoEB from "./font/AppleSDGothicNeoEB.ttf";
import AppleSDGothicNeoM from "./font/AppleSDGothicNeoM.ttf";
import AppleSDGothicNeoL from "./font/AppleSDGothicNeoL.ttf";
// import selectArrow from "../assets/share/selectArrow.svg";

export default createGlobalStyle`
  ${reset};

  @font-face {
    font-family: "bold";
    src: url(${AppleSDGothicNeoEB});
  }

  @font-face {
    font-family: "medium";
    src: url(${AppleSDGothicNeoM});
  }

  @font-face {
    font-family: "light";
    src: url(${AppleSDGothicNeoL});
  }
  
  * {
    box-sizing: border-box;
    /* white-space: nowrap; */
    scroll-behavior: smooth;
  }

  body {
    width: 100%;
    max-width: 768px;
    font-size: 16px;
    font-family: 'AppleSDGothicNeoMedium', sans-serif;
    overflow: scroll;
    box-sizing: border-box;
    scrollbar-width: none;
    -ms-overflow-style: none;

    body::-webkit-scrollbar {
      display: none;
    }
    line-height: 1.5;
  }

  a {
    color: ${colors.blackColor};
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    border: none;
    outline: none;

    &::placeholder {
      font-weight: 400;
    }
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: ${colors.whiteColor};
  }

  select {
    outline: none;
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    cursor: pointer;
  }
`;
