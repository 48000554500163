import React from "react";
import {
  Wrapper,
  Title,
  CompanyTitle,
  Container,
  InfoBox,
  Text,
} from "./styles";

const CompanyInfo = () => {
  return (
    <Wrapper>
      <CompanyTitle>회사정보</CompanyTitle>
      <InfoBox>
        <Container>
          <Title>설립연도</Title>
          <Text>1986년 설립</Text>
        </Container>
        <Container>
          <Title>본사 위치</Title>
          <Text>서울시 금천구 가산디지털2로 123 월드메르디앙2차 707호</Text>
        </Container>
        <Container>
          <Title>지점 위치</Title>
          <Text>1986년 설립</Text>
        </Container>
        <Container>
          <Title>주요 서비스</Title>
          <Text>
            히포크라테스 계열 병·의원 <br />
            종합 플랫폼
          </Text>
        </Container>
      </InfoBox>
    </Wrapper>
  );
};

export default CompanyInfo;
