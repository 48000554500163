import React from "react";
import SlideBanner from "../../components/features/Home/SlideBanner";
import IntroSection from "../../components/features/Home/IntroSection";
import ServiceImage from "../../components/features/Home/ServiceImage";
import MainText from "../../components/public/MainText";
import History from "../../components/features/Home/History";
import Address from "../../components/features/Home/Address";

const Home = () => {
  return (
    <>
      <SlideBanner />
      <IntroSection />
      <ServiceImage />
      <MainText
        text={`30여년 오직 한길만 걸으며\n병원의 성공과 함께 해 온 메디칼소프트`}
        subText={"소프트웨어를 통해 꿈꾸는 가치를 실현합니다."}
      />
      <History />
      <Address />
    </>
  );
};

export default Home;
