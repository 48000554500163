import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 18px;
  margin-top: 60px;
  margin-bottom: 60px;
`;
export const CompanyTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0px;
  border: 0px solid ${colors.borderGrayColor};
  border-bottom-width: 1px;
  border-top-width: 1px;
  & + & {
    border-top-width: 0px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  width: 36%;
`;
export const InfoBox = styled.div``;

export const Text = styled.div`
  width: 66%;
`;
