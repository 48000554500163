import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 18px;
`;
export const Title = styled.div`
  width: 220px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
`;
export const Contents = styled.div``;
export const MapBox = styled.div`
  width: 100%;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export const AddressText = styled.div`
  margin-top: 24px;
`;
