import React from "react";
import { Wrapper, Label, Container, Contents, Line } from "./styles";
import CheckBox from "../StyledCheckBox/CheckBox";
import colors from "../../../../styles/colors";

const AgreeBox = ({ label, item, values, onChange }) => {
  return (
    <Wrapper>
      <Label>
        {label.split("\n").map((text, index) => (
          <span key={`${index}-text`}>
            {text} <br />
          </span>
        ))}
      </Label>
      <Line />
      <Container>
        <Contents>
          근로자는 근로조건의 향상을 위하여 자주적인 단결권·단체교섭권 및
          단체행동권을 가진다. 모든 국민은 신속한 재판을 받을 권리를 가진다.
          형사피고인은 상당한 이유가 없는 한 지체없이 공개재판을 받을 권리를
          가진다. 국가는 농지에 관하여 경자유전의 원칙이 달성될 수 있도록
          노력하여야 하며, 농지의 소작제도는 금지된다. 국가는 주택개발정책등을
          통하여 모든 국민이 쾌적한 주거생활을 할 수 있도록 노력하여야 한다.
        </Contents>
        <CheckBox
          item={item}
          $fontColor={colors.blackColor}
          fontFamil="AppleSdNeoBold"
          values={values}
          onChange={onChange}
        />
      </Container>
    </Wrapper>
  );
};

export default React.memo(AgreeBox);
