import React, { useState, useRef } from 'react';
import { Wrapper, Image, ContentsWrapp, Contents, Title, Line, TextBlock, Square, Text, DownBox, DownLoadButton, ArrowBox, SlideArrow, SlideWrapper, SwipeImage } from '../styles';
import MainText from '../../../components/public/MainText';
import NoImage from '../../../assets/images/service_detail_banner.jpeg';
import { IoArrowDownCircleOutline } from 'react-icons/io5';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5';
import 'swiper/css';
import 'swiper/css/navigation';
import defaultType from '../../../assets/images/defaultType.png';
import simpleType from '../../../assets/images/simpleType.png';
import wideType from '../../../assets/images/wideType.png';
import colors from '../../../styles/colors';
import { useQuery } from '@apollo/client';
import { SEE_SERVICE } from '../../../graphql/Service/query';

const images = [
  { id: 1, src: defaultType },
  { id: 2, src: simpleType },
  { id: 3, src: wideType },
];

const ServiceDetailCrm = ({ state = '' }) => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [serviceType, setServiceType] = useState('EMR');
  const [hsiServiceType, setHsiServiceType] = useState('EMR');
  const [detailTabName, setDetailTabName] = useState('CRM');
  const [hsiDetailTabName, setHsiDetailTabName] = useState('CRM');

  const { loading, data } = useQuery(SEE_SERVICE, {
    variables: {
      serviceType: serviceType,
      detailTabName: detailTabName,
      hsiServiceType: hsiServiceType,
      hsiDetailTabName: hsiDetailTabName,
    },
  });

  const detailContents = data?.seeServiceContent;
  const serviceImg = data?.seeServiceImg;
  const serviceDetailBanner = data?.seeHPServiceDetail?.hsd_url;

  return (
    <Wrapper>
    {!loading && (
      <>
        <MainText text={`사용성을 극대화한 EMR 차트`} subText={'진료관리, 병원경영, 고객관리 등 의료행위에 \n 집중할 수 있도록 설계하였습니다.'} />
        <Image src={serviceDetailBanner ? serviceDetailBanner : NoImage} />
        {detailContents?.map((item, index) => {
          const isDownload = detailContents?.length > 1 ? index === 0 : index === 0;

          return (
            <ContentsWrapp key={'ServiceBlock' + index}>
              {isDownload && (
                <DownBox>
                  <DownLoadButton>
                    히포크라테스 브로슈어 다운
                    <IoArrowDownCircleOutline style={{ marginLeft: 8 }} />
                  </DownLoadButton>
                </DownBox>
              )}
              <Contents>
                <Title>{item.title}</Title>
                <Line $margin="bottom" $border={'black'} />
                {item?.serviceDetail?.map((content, index) => (
                  <TextBlock key={'Service+contents' + index}>
                    <Square>▪</Square>
                    <Text>{content}</Text>
                  </TextBlock>
                ))}
                <Line $margin="top" />
              </Contents>
            </ContentsWrapp>
          );
        })}
        <SlideWrapper>
          <Swiper
            autoplay={false}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            pagination={{ type: 'fraction' }}
            modules={[Autoplay, Navigation, Pagination]}
            onSlideChange={(e) => setMainImageIndex(e.activeIndex)}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.activeIndex = mainImageIndex;
              swiper.navigation.update();
            }}
          >
            {serviceImg?.length > 0
              ? serviceImg.map((item, index) => (
                  <SwiperSlide
                    key={`img-${index}`}
                    style={{
                      display: 'flex',
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SwipeImage src={item.hsi_img} />
                  </SwiperSlide>
                ))
              : images.map((item) => (
                  <SwiperSlide
                    key={`img-${item.id}`}
                    style={{
                      display: 'flex',
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SwipeImage src={item.src} />
                  </SwiperSlide>
                ))}

            <ArrowBox>
              <SlideArrow ref={navigationPrevRef}>
                <IoChevronBackOutline fontSize={20} color={colors.blackColor} />
              </SlideArrow>
              <SlideArrow ref={navigationNextRef}>
                <IoChevronForwardOutline fontSize={20} color={colors.blackColor} />
              </SlideArrow>
            </ArrowBox>
          </Swiper>
        </SlideWrapper>
      </>
    )}
  </Wrapper>
  );
};

export default ServiceDetailCrm;
