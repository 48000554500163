import React from "react";
import { Wrapper, Label, CheckList } from "./styles";
import CheckBox from "./CheckBox";

const StyledCheckBox = ({
  name,
  label,
  checkBoxList = [],
  onChange,
  values,
}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <CheckList>
        {checkBoxList.map((item, index) => (
          <CheckBox
            key={`${index}-checklist`}
            item={item}
            name={name}
            onChange={onChange}
            values={values}
          />
        ))}
      </CheckList>
    </Wrapper>
  );
};

export default React.memo(StyledCheckBox);
