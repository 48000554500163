import React, { useState } from "react";
import { Wrapper, SideTab, TabMenu, SideBar, Container, Line } from "./styles";
import NoticeBoard from "../NoticeBoard";
import Inquire from "../Inquire";
import ServiceCenter from "../ServiceCenter";

const SupportTab = () => {
  const [active, setActive] = useState("공지사항");
  return (
    <Wrapper>
      <SideTab>
        <TabMenu
          $active={active === "공지사항"}
          onClick={() => setActive("공지사항")}
        >
          공지사항
          <SideBar $active={active === "공지사항"} width={60} />
        </TabMenu>
        <TabMenu
          $active={active === "문의하기"}
          onClick={() => setActive("문의하기")}
        >
          문의하기
          <SideBar $active={active === "문의하기"} width={60} />
        </TabMenu>
        <TabMenu
          $active={active === "고객센터"}
          onClick={() => setActive("고객센터")}
        >
          고객센터
          <SideBar $active={active === "고객센터"} width={60} />
        </TabMenu>
      </SideTab>
      <Line />

      <Container>
        {active === "공지사항" && <NoticeBoard />}
        {active === "문의하기" && <Inquire />}
        {active === "고객센터" && <ServiceCenter />}
      </Container>
    </Wrapper>
  );
};

export default React.memo(SupportTab);
